.topMateria {
  font-size: 1.2rem;
  margin-bottom: 8px;
  display: block;
  background: #f7f7f7de;
  padding: 8px 5px;
  border-radius: 5px;
  border-left: 5px solid var(--item-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.materia {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 3rem;
}

.materia .material {
  margin: 12px 0px;
}

/* Registros Popup */
.popupRegistros {
  padding-right: 10px;
}

.listaRegistros {
  list-style: none;
  padding: 0;
  margin: 0;
}

.registroSeparador {
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 1rem;
  color: #555;
  padding: 4px 0;
}

.registroItem {
  padding: 10px;
  border-bottom: 1px solid #eee;
  font-size: 0.95rem;
}

.registroNome {
  color: #2c3e50;
}

.registroData {
  color: gray;
  font-size: 0.85rem;
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 150px;
  border-radius: 3px;
  background-color: #f9f9f9;
}

.notFound .icon {
  font-size: 2rem;
  color: var(--primary-color);
}

.notFound .registroNome {
  font-size: 1.2rem;
  color: var(--primary-color);
}

@media (max-width: 500px) {
  .materia .material {
    margin: 30px 0px;
  }
}
