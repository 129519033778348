.all {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  background-color: var(--background-color-3);
}

.home {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
  gap: 10px;
}

.homeWidgets {
  display: flex;
  margin: 20px;
}

.topAreaInfo {
  width: 100%;
  height: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: var(--background-color-1);
  border-bottom: 2px solid rgb(233, 233, 233);
  gap: 2px;
  margin-bottom: 0px;
}

.topAreaInfo .container {
  width: 95%;
  height: 100%;
  display: flex;
}

/* container2*/

.container2 {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 120px;
  justify-content: center;
  background-color: var(--background-color-2);
  border-top: 2px solid rgba(233, 233, 233, 0.829);
}

.container2 .painel {
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 10px;
}
.container2 .painel .rightArea {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}
.container2 .painel .leftArea {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.container2 .painel .rightArea .inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 280px;
  height: 40px;
  background-color: white;
  border: 1px solid #ccc;
  color: rgb(202, 202, 202) ;
  border-radius: 3px;
  padding: 0 10px;
  transition: 0.2s ease-in-out;
}

.container2 .painel .rightArea .inputWrapper:hover {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.container2 .painel .rightArea .icon {
  color: #888;
  font-size: 16px;
  margin-right: 8px;
}

.container2 .painel .rightArea .input {
  border: none;
  outline: none;
  width: 100%;
  max-width: 500px;
  font-size: 14px;
  background: transparent;
  color: rgb(131, 131, 131); /* Cor da letra cinza */
  font-weight: 500;
}

.topAreaInfo.noButtons {
  height: auto; /* Ajuste dinâmico */
  height: 80px;
}

.painel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 10px;
}

.painel .start {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
}

.painel .start h1 {
  height: 100%;
  font-size: 30px;
  margin: 0px;
  padding: 0px;
}

.painel .end {
  height: 100px;
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
}

.painel .end input[type="date"] {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 3px;
  padding-left: 3px;
  font-size: 11px;
  cursor: pointer;
}

.painel .end input[type="date"]::-webkit-calendar-picker-indicator {
  color: red;
}

.bottomAreaInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 100%;
  min-height: 90vh;
}

/* Ícone dentro dos botões */
.icon {
  font-size: 13px;
}

.returnPainelArrow {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  margin: 0px;
  padding: 0px;
  margin-top: 2.5px;
  padding-right: 3px;
}

.returnPainelArrow a {
  display: flex;
  justify-content: end;
  align-items: center;
  text-decoration: none;
  color: var(--text-color-1);
  font-size: 20px;
  transition: 0.5s;
  padding: 10px;
  border-radius: 50%;
}
.returnPainelArrow a:hover {
  display: flex;
  justify-content: end;
  align-items: center;
  text-decoration: none;
  color: var(--text-color-1);
  font-size: 20px;
  transition: 0.5s;
  background-color: rgba(192, 192, 192, 0.438);
  padding: 10px;
}

@media (max-width: 700px) {
  .home {
    width: 100%;
    height: 100%;
  }
  .container2 {
  }

  .container2 .painel {
  }
  .container2 .painel .leftArea{
    flex-direction: column;
    width: 100%;
  }

  .container2 .painel .rightArea {
    width: 100%;
  }

  .container2 .painel .rightArea .inputWrapper {
    width: 100%;
  }

  
}

@media (max-width: 550px) {
  .topAreaInfo {
    height: auto;
    max-height: none;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .painel {
    height: auto;
    flex-direction: column;
    align-items: start;
    padding-bottom: 10px;
  }

  .painel .start {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 15px 0px;
    display: flex;
    align-items: center;
  }

  .painel .end {
    width: 100%;
    height: auto;
    padding-right: 0px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding-top: 0px;
  }

  .container2 {
    padding: 10px 0px;
  }

}
