.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
  min-width: 32px; /* Garante tamanho mínimo */
  min-height: 32px; /* Garante tamanho mínimo */
}

/* Efeito ao passar o mouse */
.iconButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Ícone sempre centralizado */
.icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 📌 Tamanhos padrão */
.small {
  width: 32px;
  height: 32px;
}

.medium {
  width: 40px;
  height: 40px;
}

.large {
  width: 48px;
  height: 48px;
}

/* 📱 Responsividade para telas menores */
@media (max-width: 768px) {
  .iconButton {
    width: 36px;
    height: 36px;
    padding: 6px;
  }

  .icon {
    width: 18px;
    height: 18px;
  }
}

/* 📱 Responsividade para telas muito pequenas */
@media (max-width: 550px) {
  .iconButton {
    width: 32px;
    height: 32px;
    padding: 4px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}
