.all {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.home {
  width: 95%;
  height: 95%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow-y: auto;
}

.returnPainelArrow {
  padding-top: 20px;
  width: 96%;
}

.returnPainelArrow a {
  width: 70px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  color: #ec3237;
  font-size: 12px;
  transition: 0.5s;
}

.returnPainelArrow a:hover {
  color: #c4292e;
}

.topAreaInfo {
  width: 95%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 20px;
}

.topAreaInfo .start {
  height: 100%;
}

.topAreaInfo .start h1 {
  font-size: 18px;
  margin: 0px;
  padding: 0px;
}

.topAreaInfo .start p {
  padding-right: 20px;
  font-size: 11px;
  margin: 0px;
  padding: 0px;
  color: gray;
}

.userList {
  width: 95%;
  height: 95%;
  flex: 4;
}

.userListEdit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #ffde59;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  transition: 1s;
}

.userListEdit:hover {
  background-color: #ffd220;
}

.Aprove {
  font-size: 25px;
  background-color: rgba(212, 212, 212, 0.075);
  cursor: pointer;
  transition: 1s;
  margin-right: 10px;
  padding: 5px;
  height: 90%;
}
.Aprove:hover {
  background-color: rgba(212, 212, 212, 0.541);
}

.Reprove {
  font-size: 25px;
  background-color: rgba(212, 212, 212, 0.075);
  cursor: pointer;
  transition: 1s;
  padding: 5px;
  height: 90%;
}

.Reprove:hover {
  background-color: rgba(212, 212, 212, 0.541);
}

.timeVerify {
  color: #ffe712;
  font-weight: 800;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Espaçamento entre os botões */
  height: 100%;
}

.resendButton {
  display: flex;
  align-items: center;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease-in-out;
  margin-left: 8px;
}

.resendButton:hover {
  background-color: #45a049;
}

@media (max-width: 700px) {
  .home {
    width: 100%;
    height: 100%;
  }
}
