.boletinsList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  gap: 10px;
}

.boletinsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.boletimItem {
  background: #ffffff;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  width: 87%;
}

.boletimItem .contentBoletimItem {
  padding: 20px 10px;
}

.boletimItem:hover {
  transform: scale(1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.header h3 {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.criadoPor {
  font-size: 14px;
  color: #888;
}

.descricao {
  font-size: 16px;
  color: #555;
  margin: 12px 0;
}

.materias {
  margin: 16px 0;
}

.materias h4 {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.materiaItem {
  font-size: 14px;
  color: #555;
  list-style: disc;
  margin-left: 20px;
}

.dataCriacao {
  font-size: 14px;
  color: #999;
  margin-top: 12px;
}

.visualizarButton {
  background: #ec3237;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.visualizarButton:hover {
  background: #cd2a2e;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
  font-size: 20px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.content {
  max-height: 400px;
  overflow-y: auto;
}

.filters {
  width: 89.5%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
}

.filters div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filters div label {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
  color: #c72a2f;
}

.filters select {
  cursor: pointer;
  border-radius: 5px;
  width: 150px;
  padding: 7px 15px 7px 15px;
  border: solid 1px rgba(226, 226, 226, 0.863);
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  color: gray;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  background-color: white;
}

.filters select:hover {
  outline: none;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.filters select:focus {
  outline: none;
  border: 1px solid #d4d4d4;
}

.action_buttons {
  display: flex;
  justify-content: end;
  padding-right: 40px;
  align-items: center;
  height: 10%;
  width: 100%;
  gap: 10px;
}

.action_buttons button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #7a0509;
  background-color: #ff8387ee;
  font-size: 15px;
  transition: 1s;
  cursor: pointer;
}

.action_buttons button:hover {
  color: #7a0509b7;
  background-color: #ff83879d;
}

.action_buttons .updateButton {
  background-color: #fff383ee;
  color: #665d07ee;
}

.action_buttons .updateButton:hover {
  background-color: #dacf70ee;
}

.action_buttons .downloadExcelButton {
  background-color: #175935;
  color: #31be7d;
}
.action_buttons .downloadExcelButton:hover {
  background-color: #175935d5;
  color: #31be7d;
}

@media (max-width: 700px) {
  .filters {
    width: 95%;
    min-height: 90px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 20px;
    gap: 10px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
