.chart {
    padding: 10px;
    width: 90%;
}

.chartTitle {
    margin-bottom: 20px;
}

.ResponsiveContainer {
    width: 90%;
}

