.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    max-height: 100%;
    overflow-y: auto;
}

.categories {
    width: 98%;
    border-radius: 3px;
    background-color: white;
    border: solid 0.5px rgba(240, 240, 240, 0.89);
    padding: 5px 5px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.categories h2 {
    width: 97%;
    font-size: 17px;
    font-weight: 800;
    color: var(--primary-color);
}

.categories .item {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 85%;
    gap: 10px;
    border-bottom: solid 0.3px rgba(226, 226, 226, 0.5);
    padding-bottom: 15px;
    padding-top: 10px;
    cursor: pointer;
}

.categories .item .colorBoll {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.categories .item .nameCat {
    color: red;
    font-size: 14px;
    font-weight: 500;
}

.category {
    margin-bottom: 10px;
    width: 97%;
    gap: 10px;
    cursor: pointer;
}

.category span{
    padding-left: 10px;
    color: var(--primary-color);
    font-weight: 600;
    transition: color 0.3s ease;
}

.category span:hover {
    color: #24303bbd;
}

.graphic {
    width: 98%;
    border-radius: 3px;
    background-color: white;
    border: solid 0.5px rgba(240, 240, 240, 0.89);
    padding: 5px 5px;
    margin-top: 0px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .categories {
        width: 97%;
        padding: 1px 1px;
    }

    .graphic {
        width: 97%;
        padding: 1px 1px;
    }
}


