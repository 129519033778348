.inputContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    width: 100%;
}

.inputContainer .MuiOutlinedInput-root {
    padding-left: 40px; /* Dá espaço para o ícone */
}

.icon {
    position: absolute;
    left: 12px;
    color: #6b7280;
    pointer-events: none; /* 🔥 Garante que o clique passe direto para o input */
}

.loadingIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Ajuste de tamanhos */
.small {
    width: 180px;
}

.medium {
    width: 250px;
}

.large {
    width: 350px;
}
