.all {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.home {
    width: 95%;
    height: 95%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow-y: auto;
}

.homeWidgets {
    display: flex;
    margin: 20px;
}

.topAreaInfo {
    width: 95%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.topAreaInfo .start {
    height: 100%;
}

.topAreaInfo .start h1 {
    font-size: 18px;
    margin: 0px;
    padding: 0px;
}

.topAreaInfo .start p {
    padding-right: 20px;
    font-size: 11px;
    margin: 0px;
    padding: 0px;
    color: gray;
}

.topAreaInfo .end {
    height: 100%;
    width: 500px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding-right: 20px;
}

.topAreaInfo .end input[type="date"] {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 3px;
    padding-left: 3px;
    font-size: 11px;
    cursor: pointer;
}

.topAreaInfo .end input[type="date"]::-webkit-calendar-picker-indicator {
    color: red;
}

.topAreaInfo .end buttom {
    border-radius: 4px;
    color: white;
    font-size: 11px;
    font-weight: 700;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ec3237;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 1s;
}

.topAreaInfo .end buttom:hover {
    background-color: #c4292e;
}

.bottomAreaInfo {
    overflow-y: auto;
    border-top: solid 1px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
}

.welcome {
    width: 94%;
    font-size: 20px;
    margin: 0px;
    padding: 0px;
    color: var(--text-color-1);
    font-weight: 900;
}

@media (max-width:700px) {
    .home {
        width: 100%;
        height: 100%;
    }
}

@media (max-width:450px) {
    .topAreaInfo {
        flex-direction: column;
        align-items: start;
        padding-bottom: 50px;
    }

    .topAreaInfo .end {
        width: 100%;
        padding-right: 0px;
        justify-content: start;
        padding-top: 10px;
    }
}