.customSelectContainer {
    position: relative;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

/* Animação ao abrir */
.customSelectContainer.open {
    transform: scale(1.02);
}

/* Aparência padrão */
.customSelect {
    appearance: none;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 3px;
    font-weight: 600;
    color: #374151;
    cursor: pointer;
    text-align: center;
    min-width: 100px;
    max-width: 100%;
    text-align-last: center; /* Centraliza o texto no select */
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
}

/* Quando o select estiver aberto */
.customSelect.active {
    border-color: var(--primary-color);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

/* 📌 Estilos para tamanhos iguais aos botões */
.customSelect.small {
    font-size: 12px;
    padding: 6px 12px;
    height: 32px;
}

.customSelect.medium {
    font-size: 14px;
    padding: 10px 20px;
    height: 40px;
}

.customSelect.large {
    font-size: 16px;
    padding: 12px 24px;
    height: 48px;
}

/* Animação ao abrir a lista de opções */
.customSelect option {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    transform: translateY(-5px);
}

/* Quando o select está aberto, as opções aparecem suavemente */
.customSelect.active option {
    opacity: 1;
    transform: translateY(0);
}

/* Responsividade 📱 */
@media (max-width: 768px) {
    .customSelectContainer {
        width: 100%;
    }
    .customSelect {
        width: 100%;
        font-size: 12px;
        padding: 8px 16px;
        height: 36px;
    }

    .customSelect.small {
        font-size: 10px;
        padding: 5px 10px;
        height: 28px;
    }

    .customSelect.medium {
        font-size: 12px;
        padding: 8px 16px;
        height: 36px;
    }

    .customSelect.large {
        font-size: 14px;
        padding: 10px 20px;
        height: 44px;
    }
}

@media (max-width: 550px) {
    .customSelect {
        width: 100%;
        font-size: 12px;
        padding: 8px;
        height: 34px;
    }

    .customSelect.small,
    .customSelect.medium,
    .customSelect.large {
        font-size: 10px;
        padding: 6px 12px;
        height: 30px;
    }
}
