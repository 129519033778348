.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1051;
}

.popup {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.header h2 {
    font-size: 18px;
    margin: 0;
    color: #1f2937;
}

.closeButton {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 5px;
}

.message {
    font-size: 16px;
    color: #4b5563;
    text-align: center;
    margin-bottom: 20px;
}

.warning {
    font-size: 14px;
    color: #dc2626;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    text-align: center;
}

.footer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
