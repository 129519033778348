/* Contêiner principal */
.notificacoesContainer {
  width: 95%;
  height: 100%;
  margin: auto;
}

/* Lista de Notificações */
.listaNotificacoes {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

/* Cada item da notificação */
.notificacaoItem {
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 15px;
  background: #ffffff;
  border-left: 6px solid #ec3237;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notificacaoItem:hover {
  transform: scale(1.002);
  background: #f9f9f9;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

/* Cabeçalho da notificação */
.cabecalho {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.tipo {
  padding: 0px 0px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  color: #b52b3a;
}

.cabecalho strong {
  font-size: 19px;
  padding: 5px 0px;
}

.notificacaoItem p {
    background-color: rgb(255, 221, 221);
    border-left: 3px solid #c42b2f;
    margin: 0px;
    padding: 20px 10px;
    color: #8b0000;
  }

/* Estilização para cada tipo de notificação */
.Informativo {
  background: #e3f2fd;
  color: #1565c0;
  background-color: red;
}

.Importante {
  background: #fff3cd;
  color: #856404;
}

.Urgente {
  background: #f8d7da;
  color: #721c24;
}

/* Rodapé da notificação */
.rodape {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/* Ajuste no agrupamento de ações */
.acoes {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* Data de criação */
.data {
  padding: 0px 0px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
}

/* Ícone de prioridade */
.prioridadeIcon {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(245, 245, 245);
  border: 0.5px solid rgb(240, 240, 240);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
}

/* Botões com design aprimorado */
.deleteButton,
.editButton {
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  border: none;
}

/* Botão Deletar */
.deleteButton {
  background-color: #e63946;
  color: white;
}

.deleteButton:hover {
  background-color: #b52b3a;
  transform: translateY(-2px);
}

/* Botão Editar */
.editButton {
  background: #007bff;
  color: white;
}

.editButton:hover {
  background: #0056b3;
  transform: translateY(-2px);
}

/* Botão de Criar Notificação */
.createButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: #ec3237;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: background 0.3s ease-in-out;
}

.createButton:hover {
  background: #c42b2f;
}

/* Ícones de prioridade */
.prioridadeBaixa {
  color: #28a745;
}

.prioridadeMediaBaixa {
  color: #ffc107;
}

.prioridadeMedia {
  color: #ff9800;
}

.prioridadeAlta {
  color: #dc3545;
}

.prioridadeMuitoAlta {
  color: #8b0000;
}

.prioridadeUrgente {
  color: #ff0000;
  font-size: 22px;
}

/* Botão padrão */
.toggleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  border: none;
  transition: all 0.3s ease-in-out;
}

/* Botão quando o status está "Ativo" (Mostra "Bloquear Acesso") */
.bloquear {
  background-color: #e63946; /* Vermelho */
  color: white;
}

.bloquear:hover {
  background-color: #b52b3a;
  transform: translateY(-2px);
}

/* Botão quando o status está "Inativo" (Mostra "Liberar Acesso") */
.liberar {
  background-color: #28a745; /* Verde */
  color: white;
}

.liberar:hover {
  background-color: #1e7e34;
  transform: translateY(-2px);
}

/* Ícones dentro do botão */
.toggleButton svg {
  font-size: 18px;
}


/* Responsividade */
@media (max-width: 768px) {
  .notificacaoItem {
    padding: 15px;
  }

  .rodape {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .acoes {
    flex-direction: row;
    gap: 5px;
  }

  .deleteButton,
  .editButton {
    padding: 6px 10px;
    font-size: 12px;
  }
}
