.defaultButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px; /* Espaço entre ícone e texto */
    font-weight: 600;
    color: #ffffff;
    background-color: #374151;
    border: 1px solid #374151;
    border-radius: 3px;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-size: 14px;
    min-width: 100px;
    max-width: 100%;
    text-align: center;
    white-space: nowrap;
    height: 40px; /* 🔥 Altura fixa para manter consistência */
}

/* Ajuste quando o botão NÃO tem ícone */
.defaultButton.noIcon {
    gap: 0; /* Remove o espaço extra */
    padding: 10px 24px; /* Mantém alinhamento correto */
}

/* 📌 Tamanhos padrão */
.defaultButton.small {
    font-size: 12px;
    padding: 6px 12px;
    height: 32px; /* 🔥 Mantém altura fixa para pequenos */
}

.defaultButton.medium {
    font-size: 14px;
    padding: 10px 20px;
    height: 40px; /* 🔥 Mantém altura fixa */
}

.defaultButton.large {
    font-size: 16px;
    padding: 12px 24px;
    height: 48px; /* 🔥 Mantém altura fixa para grandes */
}

/* 🎯 Ícone ajustado para alinhar corretamente */
.icon {
    font-size: 18px;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Ajuste no texto para manter centralizado */
.buttonText {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 📱 Responsividade para telas menores */
@media (max-width: 768px) {
    .defaultButton {
        font-size: 12px;
        padding: 8px 16px;
        height: 36px; /* 🔥 Ajusta altura em telas menores */
    }

    .defaultButton.noIcon {
        padding: 8px 20px; /* Ajusta quando não tem ícone */
    }

    .defaultButton.small {
        font-size: 10px;
        padding: 5px 10px;
        height: 28px;
    }

    .defaultButton.medium {
        font-size: 12px;
        padding: 8px 16px;
        height: 36px;
    }

    .defaultButton.large {
        font-size: 14px;
        padding: 10px 20px;
        height: 44px;
    }
}

/* 📱 Responsividade para telas muito pequenas */
@media (max-width: 550px) {
    .defaultButton {
        width: 100%;
        font-size: 12px;
        padding: 8px;
        height: 34px;
    }

    .defaultButton.noIcon {
        padding: 8px 16px;
    }

    .defaultButton.small,
    .defaultButton.medium,
    .defaultButton.large {
        font-size: 10px;
        padding: 6px 12px;
        height: 30px;
    }

    .icon {
        font-size: 16px;
    }
}
