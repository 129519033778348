.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  max-height: 90vh; /* <-- Limita a altura máxima da popup */
  overflow: hidden;  /* <-- Garante que o scroll seja interno */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  display: flex;
  flex-direction: column; /* para permitir que o contentContainer use o espaço restante */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.header h2 {
  font-size: 18px;
  margin: 0;
}

.closeButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;         /* <-- Ativa scroll vertical se passar do limite */
  max-height: 60vh;         /* <-- Ajuste conforme o tamanho do popup */
  padding-right: 6px;       /* para não esconder conteúdo atrás da scrollbar */
}

.dataRow {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: #f9f9f9;
  border-radius: 5px;
  font-size: 14px;
}

.dataRow strong {
  color: #333;
}

.dataRow span {
  color: #555;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.footer {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 10px; /* Espaçamento entre os botões */
  margin-top: 15px;
}

.buttonSpacing {
  min-width: 100px; /* Garantir um tamanho mínimo */
  background-color: yellow;
}

/* Responsividade */
@media (max-width: 480px) {
  .popup {
    width: 90%;
    padding: 15px;
  }

  .header h2 {
    font-size: 16px;
  }

  .contentContainer {
    font-size: 14px;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }
}
