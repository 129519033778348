.section {
  width: 97%;
  background: white;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  margin: 10px 0px;
}

.fixed {
  height: 300px;
  overflow-y: auto;
}

.flexible {
  min-height: 100px;
  max-height: none; 
}
