.container {
    padding: 0px;
    width: 95%;
    margin: auto;
    background: #ffffff;
    padding-top: 20px;
}

.title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #2D3748;
    margin-bottom: 25px;
}

.filters {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.filter {
    flex: 1;
}

.stats {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
}

.statCard {
    flex: 1;
    background: #F7FAFC;
    text-align: center;
    border: 1px solid #E2E8F0;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
}


.statCard:hover {
    transform: scale(1.03);
}

.chartsContainer {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap; /* Permite que os gráficos fiquem um abaixo do outro em telas menores */
    margin-bottom: 20px;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 10px;
    padding-top: 10px;
    padding-top: 10px;
}

.chartWrapper {
    flex: 1;
    min-width: 400px; /* Evita que o gráfico fique muito pequeno */
    max-width: 600px; /* Limita o tamanho máximo */
    height: 350px;
}

@media (max-width: 1024px) {
    .chartWrapper {
        min-width: 100%; /* Ocupa a largura total em telas menores */
        height: 300px; 
    }
}

@media (max-width: 768px) {
    .chartWrapper {
        height: 250px;
    }
}


.tableContainer {
    height: 500px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.status {
    padding: 6px 12px;
    border-radius: 20px;
    font-weight: bold;
    color: white;
    border: none;
}

.link {
    color: #4B98FF;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}

.link:hover {
    color: #0353e9;
}

.actionButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fc9598;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid #EC3237;
    margin-bottom: 15px;
}

.actionsLeft {
    display: flex;
    align-items: center;
    gap: 15px;
}

.actionsLeft span {
    font-size: 14px;
    font-weight: 500;
    color: #c4292e;
}

.userListEdit {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #ffde59;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.userListEdit:hover {
    background-color: #ffd220;
}

.userListDelete {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #ec3237;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.userListDelete:hover {
    background-color: #c4292e;
}

.closeButton {
    background: none;
    border: none;
    color: #c4292e;
    cursor: pointer;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
}

.closeButton:hover {
    color: #ec3237;
}