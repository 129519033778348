.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  max-height: 100%;
  overflow-y: auto;
}

.categories {
  width: 98%;
  border-radius: 3px;
  background-color: white;
  border: solid 0.5px rgba(240, 240, 240, 0.89);
  padding: 5px 5px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.categories h2 {
  width: 97%;
  font-size: 17px;
  font-weight: 800;
  color: var(--primary-color);
}

.categories .item {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 85%;
  gap: 10px;
  border-bottom: solid 0.3px rgba(226, 226, 226, 0.5);
  padding-bottom: 15px;
  padding-top: 10px;
  cursor: pointer;
}

.categories .item .colorBoll {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.categories .item .nameCat {
  color: red;
  font-size: 14px;
  font-weight: 500;
}

.category {
  margin-bottom: 10px;
  width: 97%;
  gap: 10px;
  cursor: pointer;
}

.category span {
  padding-left: 10px;
  color: var(--primary-color);
  font-weight: 600;
  transition: color 0.3s ease;
}

.category span:hover {
  color: #24303bbd;
}

.graphic {
  width: 98%;
  border-radius: 3px;
  background-color: white;
  border: solid 0.5px rgba(240, 240, 240, 0.89);
  padding: 5px 5px;
  margin-top: 0px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.historico {
  width: 98%;
  border-radius: 3px;
  background-color: white;
  border: solid 0.5px rgba(240, 240, 240, 0.89);
  padding: 10px 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;  
  margin-top: 0px;
  margin-bottom: 8px;
}

.historicoContainer {
  width: 95%;
  height: 93%;
}

.historicoTitulo {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
}

.listaRegistros {
  list-style: none;
  padding-left: 0;
  width: 100%;
  margin-bottom: 20px;
}

.registroItem {
  font-size: 0.95rem;
  margin-bottom: 12px;
  line-height: 1.4;
  color: #555;
  border-left: 3px solid var(--primary-color);
  padding-left: 12px;
  transition: background 0.3s;
  cursor: pointer;
}

.registroItem:hover {
  background-color: #f9f9f9;
  border-left-color: #1d72b8;
}

.registroNome {
  color: #1d72b8;
}

.registroData {
  font-size: 0.85rem;
  color: #888;
}

.botaoVerTodos {
  align-self: flex-end;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 3px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.botaoVerTodos:hover {
  background-color: #071b27;
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 150px;
  border-radius: 3px;
  background-color: #f9f9f9;
}

.notFound .icon {
  font-size: 2rem;
  color: var(--primary-color);
}

.notFound .registroNome {
  font-size: 1.2rem;
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .categories {
    width: 97%;
    padding: 1px 1px;
  }

  .graphic {
    width: 97%;
    padding: 1px 1px;
  }
}
