/* 🔹 Tela de carregamento com fundo verde translúcido */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8); /* Verde translúcido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000; /* Mantém acima de tudo */
}

/* 🔹 Barra de carregamento no topo */
.loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 4px;
  background-color: var(--tertiary-color); /* Verde escuro */
  animation: loadingAnimation 0.7s infinite linear;
  z-index: 10000;
}

/* 🔹 Animação da barra de progresso */
@keyframes loadingAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.all {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; 
}

.screen header {
  width: 100%;
  height: 10%;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 10px 10px rgb(126, 126, 126);
  border-bottom: 1px solid rgb(230, 230, 230);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: default;
}

.screen header .rightSide {
  width: 150px;
}

.screen header .rightSide .logo {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.screen header .rightSide .logo img {
  height: 65%;
  cursor: pointer;
}

.screen header .leftSide {
  width: 300px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.screen header .leftSide .profile {
  height: 90%;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  gap: 10px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}

.screen header .leftSide .profile .profileMenu {
  background-color: rgb(255, 255, 255);
  position: absolute;
  top: 75px;
  width: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 5px;
  display: flex;
  flex-direction: column;
  padding: 2px;
  z-index: 1;
}

.screen header .leftSide .profile a {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  color: black;
  transition: 0.5s;
  padding: 5px;
}

.screen header .leftSide .profile a:hover {
  color: #ec3237;
  border-radius: 2px;
  background-color: rgb(240, 240, 240);
}

.screen header .leftSide .profile .arrowProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.screen header .leftSide .profile img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.menuButtom {
  font-size: 27px;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.8s;
}

.menuButtom:hover {
  background-color: rgba(228, 228, 228, 0.521);
}

.menuButtomRightHeader {
  background-color: #ec3237;
  color: #fff;
  position: fixed;
  /* Alterado para 'fixed' para que o botão flutue pela tela */
  bottom: 70%;
  /* Defina a posição inicial do botão na parte inferior da tela */
  right: 95.7%;
  /* Defina a posição inicial do botão à direita da tela */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  animation: floating 2s infinite ease-in-out;
  /* Adicionando a animação de flutuação */
  z-index: 1;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
    /* Define a altura máxima da flutuação */
  }

  100% {
    transform: translateY(0);
  }
}

.menuButtomRightHeader:hover {
  background-color: #c72a2f;
}

.all_bottom_page {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: start;
  align-items: start;
}

.rightHeader {
  height: 100vh;
  background-color: --var(--background-color-1);
  display: flex;
  flex-direction: column;
  transition: 1s;
  transition: width 0.5s ease, transform 0.5s ease;
  transform: translateX(0);
  position: relative;
  box-shadow: 2px 0px 2px rgba(163, 163, 163, 0.363);
  transition: transform 0.5s ease-in-out;
}

/* 🔹 Container geral da barra de pesquisa */
.searchContainer {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center;
  width: 99.8%;
  height: 73px; /* Altura menor para manter o design mais limpo */
  background: linear-gradient(
    -30deg,
    var(--text-color-1),
    var(--primary-color)
  );
}

/* 🔍 Caixa que envolve a lupa, input e o botão de limpar */
.searchBox {
  display: flex;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.356
  ); /* Fundo branco igual ao exemplo da segunda imagem */
  border-radius: 3px; /* Borda arredondada */
  width: 80%;
  height: 20px;
  max-width: 280px; /* Defina um limite para largura */
  padding: 8px;
  position: relative;
}

/* 🔎 Ícone da lupa */
.searchIcon {
  color: #4f606d; /* Cinza escuro */
  font-size: 20px;
  margin-right: 10px;
}

/* 📝 Input de busca */
.searchInput {
  flex: 1;
  border: none;
  background: transparent;
  color: #4f606d; /* Texto em cinza escuro */
  font-size: 14px;
  outline: none;
}

/* 🔹 Placeholder estilizado */
.searchInput::placeholder {
  color: white !important; /* Cor cinza mais forte */
  font-size: 15px;
  font-weight: 400;
  opacity: 1 !important; /* Garante visibilidade */
}

/* ❌ Ícone de limpar */
.clearIcon {
  color: #4f606d;
  font-size: 18px;
  cursor: pointer;
}

/* Placeholder estilizado */
.searchInput::placeholder {
  color: #c4cdd5;
}

/* Efeito ao focar */
.searchInput:focus {
  border: none;
  outline: none;
}

.rightHeader.open {
  width: 300px;
  transform: translateX(0);
}

.rightHeader .itemsContent {
  display: flex; /* Removido "display: block;" */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* Ajustado para evitar deslocamento */
  overflow-y: auto;
  max-height: 80vh;
  width: 100%; /* Garante que o container ocupe toda a largura */
}

.rightHeader .itemsContent::-webkit-scrollbar {
  width: 12px;
}

.rightHeader .itemsContent::-webkit-scrollbar-thumb {
  background-color: #c22228ce;
  border-radius: 0px;
}

.rightHeader .itemsContent .itemElement {
  width: 100%; /* Ajustado para garantir alinhamento */
  height: 56px;
  max-width: none; /* Corrigido "auto" para "none" */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-right: solid 5px var(--primary-color-1); /* Corrigido erro na variável */
  cursor: pointer;
  transition: 0.3s; /* Diminuído o tempo para uma transição mais rápida */
  padding: 0px 16px;
  color: var(--text-color-1);
  font-weight: 800;
  font-size: 17px;
  gap: 10px;
  margin: 0;
  box-sizing: border-box;
}

.rightHeader .itemsContent .itemElement:hover {
  background-color: var(--background-color-2);
}

.rightHeader .itemsContent .itemElement .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 0;
}

.rightHeader .itemsContent .itemElement .text {
  font-size: 12px;
  margin: 0;
}

.all_bottom_page .contentScrennFull {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1; 
  min-width: 0;
  height: 100%;
  background-color: var(--background-color-3);
}

.contentScrennDash {
  width: 100%;
  height: 100%;
}

@media (max-width: 2100px) {
}

@media (max-width: 1000px) {
  .menuButtomRightHeader {
    background-color: #ec3237;
    color: #fff;
    position: fixed;
    /* Alterado para 'fixed' para que o botão flutue pela tela */
    bottom: 65%;
    /* Defina a posição inicial do botão na parte inferior da tela */
    right: 94%;
    /* Defina a posição inicial do botão à direita da tela */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
    animation: floating 2s infinite ease-in-out;
    /* Adicionando a animação de flutuação */
  }

  .screen header .rightSide .logo {
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 20px;
  }
}

@media (max-width: 700px) {
  .rightHeader {
    height: 89.5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: 1s;
    transition: width 0.5s ease, transform 0.5s ease;
    /* Adicionando transições */
    transform: translateX(0);
    /* Posição inicial quando o menu está aberto */
    position: absolute;
    /* Adicionei position relative */
    z-index: 1000;
    background-color: rgba(138, 138, 138, 0.377);
  }

  .rightHeader .itemsContent {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: 100%;
    background-color: white;
    width: 70%;
  }

  /* 🔹 Container geral da barra de pesquisa */
  .searchContainer {
    width: 70%;
  }

  .rightHeader .itemsContent .itemElement {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    transition: 1s;
    padding-top: 0px;
    padding-bottom: 0px;
    color: white;
    color: var(--text-color-2);
    gap: 7px;
    padding: 15px;
    padding-left: 50px;
  }

  .rightHeader.open {
    width: 100%;
  }

  .rightHeader .logo {
    width: 95%;
  }

  .screen {
    width: 100%;
  }

  .menuButtomRightHeader {
    background-color: #ec3237;
    color: #fff;
    position: fixed;
    /* Alterado para 'fixed' para que o botão flutue pela tela */
    bottom: 0%;
    /* Defina a posição inicial do botão na parte inferior da tela */
    right: 0%;
    /* Defina a posição inicial do botão à direita da tela */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    border-radius: 0%;
    cursor: pointer;
    font-size: 20px;
    animation: none;
    /* Adicionando a animação de flutuação */
  }

  .screen header .rightSide .logo {
    padding-left: 10px;
  }
}
