/* Fundo escurecido */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  animation: fadeIn 0.3s ease-in-out;
}

/* Caixa do popup */
.popup {
  background: #fff;
  padding: 32px;
  border-radius: 12px;
  width: 700px;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-in-out;
}

/* Cabeçalho */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 8px;
}

.header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Botão de fechar */
.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #374151;
}

/* Descrição */
.description {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 15px;
}

/* Campos de entrada */
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

/* Rodapé com botões */
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 20px;
  flex-wrap: wrap;
}

/* 🔥 RESPONSIVIDADE PARA CELULARES (TELA CHEIA) */
@media (max-width: 480px) {
  .overlay {
    background: rgba(0, 0, 0, 0.8); /* Fundo mais escuro para dar contraste */
  }

  .popup {
    width: 100vw; /* Ocupa toda a largura */
    height: 97vh; /* Ocupa toda a altura */
    max-width: 100vw;
    max-height: 100vh;
    padding: 24px; /* Reduz o padding para otimizar espaço */
    border-radius: 0; /* 🔥 Remove as bordas arredondadas */
    display: flex;
    flex-direction: column;
  }

  .header {
    padding-bottom: 12px;
  }

  .header h2 {
    font-size: 1.4rem;
  }

  .closeButton {
    font-size: 22px;
  }

  .description {
    font-size: 13px;
  }

  .inputContainer {
    flex: 1; /* Faz com que os inputs ocupem o espaço restante */
    overflow-y: auto; /* 🔥 Garante rolagem caso tenha muitos inputs */
    padding-top: 10px;
  }

  .footer {
    flex-direction: column; /* 🔥 Botões ficam um abaixo do outro */
    align-items: stretch;
    gap: 10px;
    margin-top: auto; /* 🔥 Mantém os botões no final do popup */
  }
}
