.agenda-calendario .custom-tile {
  text-align: center;
  background-color: white;
  padding: 20px;
  font-weight: 600;
  color: var(--primary-color);
}

.agenda-calendario .highlight-today {
  background-color: var(--primary-color) !important;
  border-radius: 5px !important;
  color: white !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin: auto;
}

.agenda-calendario .highlight-selected {
    background-color: transparent !important;
    border: 0px solid transparent !important;
    border-radius: 5px !important;
    color: var(--primary-color) !important;
    border-radius: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0px;
    transition: 1s;
    height: 70px;
}

.agenda-calendario .highlight-selected:hover {
  opacity: 0.9;
}

.agenda-calendario .highlight-weekend {
  background-color: #fafafaf8;
}

.agenda-calendario .highlight-other-month {
  color: gray;
  opacity: 0.5;
}

.agenda-calendario .highlight-agenda-active,
.agenda-calendario .highlight-agenda-inactive {
  position: relative;
}

.agenda-calendario .highlight-agenda-active::after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: green;
  border-radius: 50%;
}

.agenda-calendario .highlight-agenda-inactive::after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
}
