.calendarioContainer {
  width: 100%;
  max-height: 89vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: #f8f9fa;
  overflow-y: auto;
}

/* Controles superiores */
.headerControls {
  background-color: var(--background-color-1);
  border-bottom: 1px solid #d1d5db;
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Controles superiores */
.headerContainer {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}

/* Lado esquerdo (Navegação de mês) */
.leftControls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.arrowSection {
  height: 100%;
  display: flex;
  align-items: center;
  align-items: center;
}

.todayButton,
.navButton {
  background-color: #fff;
  border: 1px solid #d1d5db;
  color: #374151;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.todayButton:hover,
.navButton:hover {
  background-color: #f3f4f6;
}

/* Estilo do Mês/Ano */
.mesAno {
  font-size: 1.2rem;
  font-weight: bold;
  color: #374151;
  margin: 0 10px;
}

/* Lado direito (Seleção de visualização + Botão Criar Lembrete) */
.rightControls {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* Select para mudar a visualização */
.viewSelector {
  padding: 11px 12px;
  border: 1px solid #d1d5db;
  border-radius: 3px;
  font-weight: 300;
  cursor: pointer;
  background: #fff;
  color: var(text-color-1);
}

.viewSelector:hover {
  background-color: #f3f4f6;
}

/* Botão Criar Lembrete */
.addEventButton {
  background-color: #1f2937;
  color: white;
  font-weight: bold;
  padding: 8px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
}

.addEventButton span {
  font-size: 18px;
  margin-right: 5px;
}

.addEventButton:hover {
  background-color: #111827;
}

/* Calendário */
.calendarioContent {
  width: 100%;
  max-width: 100%;
}

@media (max-width: 768px) {
  .headerContainer {
    flex-direction: column;
    gap: 10px;
  }

  .leftControls {
    width: 100%;
    justify-content: start;
  }

  .arrowSection {
    width: auto;
    min-width: 70%;
    justify-content: center;
  }

  .mesAno {
    font-size: 1.1rem;
    font-weight: bold;
    color: #374151;
    margin: 0 10px;
  }

  /* Select para mudar a visualização */
  .viewSelector {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 3px;
    font-weight: bold;
    cursor: pointer;
    background: #fff;
    color: #374151;
  }

  .rightControls {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
}

@media(max-width: 500px) {
  .mesAno {
    font-size: 1.0rem;
    font-weight: bold;
    color: #374151;
    margin: 0 10px;
  }
}