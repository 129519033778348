/* src/components/PopUpComponent.module.css */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup {
  background: white;
  padding: 20px;
  width: 700px;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: slideDown 0.5s ease-out; 
  max-height: 400px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.closeButton {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: var(--primary-color);
}

.content {
  padding: 10px 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
}

@media (max-width: 800px) {
  .popup {
    background: white;
    padding: 15px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slideDown 0.5s ease-out; 
  }
  .content {
    padding: 10px 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: 500px;
  }
}
