.container {
  width: 98%;
  height: 100%;
  background-color: transparent;
}

.boletimTitulo {
  font-size: 24px;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 15px;
  border-bottom: 2px solid #ec3237; /* Verde suave */
  padding-bottom: 5px;
}

.alunoInfo {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.alunoInfo p {
  font-size: 16px;
  margin: 5px 0;
}

.alunoInfo strong {
  color: #ec3237;
}

.errorMessage {
  color: #f44336; /* Vermelho */
  font-weight: bold;
  margin: 20px 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.noData {
  text-align: center;
  font-size: 18px;
  color: #999;
  margin-top: 20px;
}

@media (max-width: 700px) {
  .container {
    width: 98%;
    height: 98%;
    margin-top: 0px;
    border-radius: 0px;
    padding: 10px;
  }
}
