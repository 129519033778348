.container {
    width: 90%;
    margin: auto;
    padding: 20px;
    background: #ffffff;
}

.title {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    color: #333;
}

.error {
    color: #d32f2f;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.timeInputs {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.cancelBtn {
    color: #555;
    border-color: #ccc;
}
