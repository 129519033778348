:root {
  --primary-color: #24303B;
  --secondary-color: #ffde59;
  --tertiary-color: #EC3237;
  --background-color-1: #FFFFFF;
  --background-color-2: #f4f6f8; 
  --background-color-3: #f8f9fa; 
  --text-color-1: #2b3a46; 
  --text-color-2: #202020; 
  --success-color: #28a745;
  --warning-color: #ffc107;
  --error-color: #dc3545;
  --hover-button-1:#E3E5E6;
}
