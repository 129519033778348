.calendar_container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.custom_header {
  background-color: var(--background-color-1);
  border-bottom: 1px solid #d1d5db;
  display: flex;
  justify-content: center;
  width: 100%;
}

.custom_header .header {
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.custom_header .header .right {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom_header .header .right h3 {
  color: var(--primary-color);
}

.custom_header .header .left {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.vertical_line {
  height: 30px; /* Altura da linha */
  border-left: 1px solid #cfcfcf; /* Espessura e cor da linha */
  margin: 0 10px; /* Espaçamento lateral */
}

:global(.highlight-today) {
  background-color: var(--primary-color) !important;
  border-radius: 5px !important;
  color: white !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin: auto;
}

:global(.highlight-selected) {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  border-radius: 5px !important;
  color: var(--primary-color) !important;
  border-radius: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0px;
  transition: 1s;
  height: 70px;
}

:global(.highlight-selected:hover) {
  opacity: 0.9;
}

:global(.custom-tile) {
  /* Estilos padrão para todas as datas */
  text-align: center;
  background-color: white;
  padding: 20px 20px;
  font-weight: 600;
  color: var(--primary-color);
}

:global(.highlight-weekend) {
  background-color: #fafafaf8;
  border-radius: 0px;
}

:global(.highlight-other-month) {
  color: gray; /* Cor do texto para dias de outros meses */
  opacity: 0.5; /* Tornar os dias de outros meses semi-transparentes */
  background-color: auto;
}

/* 🔥 Nova classe para marcar dias com agenda */
:global(.highlight-agenda) {
  position: relative;
}

:global(.highlight-agenda-active),
:global(.highlight-agenda-inactive) {
  position: relative;
}

/* 🔥 Cor diferente para status ativo */
:global(.highlight-agenda-active)::after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: green;
  border-radius: 50%;
}

/* 🔥 Cor diferente para status inativo */
:global(.highlight-agenda-inactive)::after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
}

@media (max-width: 900px) {
  .calendar_container {
    width: 100%;
  }
  .custom_header {
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .custom_header .header {
    display: flex;
    flex-direction: column;
  }

  .custom_header .header .right {
    width: 100%;
  }
  .custom_header .header .right h3 {
    font-size: 16px;
  }

  .custom_header .header .left {
    width: 50%;
    display: flex;
    justify-content: start;
    padding-bottom: 20px;
  }

  .vertical_line {
  }
}


@media (max-width: 600px) {
  .calendar_container {
    width: 100%;
  }
  .custom_header {
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .custom_header .header {
    display: flex;
    flex-direction: column;
  }

  .custom_header .header .right {
    width: 100%;
  }
  .custom_header .header .right h3 {
    font-size: 16px;
  }

  .custom_header .header .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;

  }

  .vertical_line {
    display: none;
  }
}
