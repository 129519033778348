.container {
    width: 90%;
    height: 100%;
    background: #ffffff;
    color: #333;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #fdf2f2;
    border-left: 4px solid rgb(187, 35, 35);
    padding: 10px 10px 10px 10px;
    color: rgb(163, 26, 26);
    font-weight: 800;
    border-radius: 5px;
}

.status {
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: #c4292e;
    border: 0.5px solid rgb(226, 226, 226);
}

.details {
    display: flex;
    gap: 15px;
    margin-bottom: 25px;
}

.card {
    flex: 1;
    background: #f7fafc;
    text-align: center;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
    border-top: 3px solid #c4292e;
}

.card:hover {
    transform: scale(1.03);
}

.chartContainer {
    margin-bottom: 30px;
}

.historico {
    margin-bottom: 30px;
}

.historico ul {
    list-style: none;
    padding: 0;
}

.historico li {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.historico .top{
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-bottom: 10px;
}

.actions {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.actionButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fc9598;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid #EC3237;
    margin-bottom: 15px;
}

.actionsLeft {
    display: flex;
    align-items: center;
    gap: 15px;
}

.actionsLeft span {
    font-size: 14px;
    font-weight: 500;
    color: #c4292e;
}


.closeButton {
    background: none;
    border: none;
    color: #c4292e;
    cursor: pointer;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
}

.closeButton:hover {
    color: #ec3237;
}
